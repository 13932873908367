html, body {
  height: 100%;
}
body{
  margin:0px;
  padding:0px;
  position: relative;
  top:0px;
  left:0px;
  user-select: none;
  background-color: #fff;
}

.App {
  text-align: center;
}

div{
  overflow: hidden;
}


.react-resizable {
  position: absolute;
  /*transition: width 0.1s ease-out;*/
  z-index:1;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}












.timeline{
  position: absolute;
  left: 400px;
  width: calc(100% - 400px);
  /*height: 500px;*/
  height: 100%;
  border-radius: 10px 0px 0px 10px;
  overflow:hidden;
}

.participants-container{
  position: sticky;
  left: 0px;
  /*margin-top: 20px;*/
  width: 195px;
  z-index:900;
  float: left;
  background-color: #444444;
  /*height: calc(100% - 20px);*/
  /*height: 2000px;*/
  overflow: hidden;
  border-right: 5px solid #eee;
}

.sections-container{
  height: 50px;
  position: sticky;
  margin-left: 200px;
  top: 15px;
  z-index:296;
  border-bottom: 2px solid #eee;
  /*width: calc(100% - 200px);*/
  width: 2000px;
background: repeating-linear-gradient(
    to right,
    #eee,
    #eee 2px,
    #e0e0e0 2px,
    #e0e0e0 100px
  );
}
.section{
  width: calc(100px - 0px);
  height: calc(100% - 0px);
  display: inline-block;
  padding: 0px;
  border-right: 0px solid #000;
  color: white;
  font-weight: bold;
  border-radius:5px;

}
.section span{
  display: flex;
  white-space: nowrap;
  overflow: hidden;
      padding: 10px;

  -webkit-mask-image: linear-gradient(to left, #0000 10px, #000f 50px, #000f 100%);
  mask-image: linear-gradient(to left, #0000 10px, #000f 50px, #000f 100%);

}
.section-time-fill{
    background-color: #0003;
    height: 8px;
    position: absolute;
    top: 0px;
    width: 18px;
    left: 0px;
    display: none;
}


.playhead{
  height: calc(100% - 4px);
  width: 0px;
  border: 2px #4445 dashed;
  position: absolute;
  top: 0px;
  left: calc(200px + 180px);
  /*transition: left 0.05s linear;*/
  z-index: 500;
}


.participant {
  height: calc(40px - 2px);
  background-color: white;
  border-bottom:2px solid #eee;
  position: relative;
      display: flex;
    align-items: center;


}
.participant-text{
  display: flex;
  overflow: hidden;
    padding:  0px 10px;

  position: absolute;
  /*top: calc(50% - 0.5em - 2px);*/
  max-height: calc(max(100%, 20px) - 10px);
  /*-webkit-mask-image: linear-gradient(to top, #fff0 0px, #ffff 10px, #ffff 100%);*/

}

.block-container{
  height: 40px;
  /*top: 72px;*/
  left: 200px;
  position: absolute;
  width: calc(100% - 200px);

  /*
  background: repeating-linear-gradient(
      to bottom,
      #0000,
      #0000 100px,
      #000 100px,
      #000 102px
  ), repeating-linear-gradient(
    to right,
    #606060,
    #606060 100px,
    #686868 100px,
    #686868 200px
  );*/

  background: repeating-linear-gradient(
      to bottom,
      #fff0,
      #fff0 38px,
      #eee 38px,
      #eee 40px
  ), repeating-linear-gradient(
    to right,
      #eee,
      #eee 2px,
      #fff 2px,
      #fff 100px
    )
}

.block-drag-wrapper {
  width: 100%;
  height: 100%;
  cursor: move;
  text-align: center;  
  overflow: hidden;
  background: #e5e5e5; 
  overflow: hidden; 
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:5px;

}

.block-text{
  
  padding: 10px;
  overflow: hidden;
  color: white;
  display: flex;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*white-space:nowrap;*/
  max-height:  calc(max(100%, 40px) - 20px);

  /*font-weight: bold;*/
  white-space: break-spaces;

  -webkit-mask-image: linear-gradient(to top, #0000 0px, #000f 1em, #000f 100%);
  mask-image: linear-gradient(to top, #0000 0px, #000f 1em, #000f 100%);

}

.sections-container .block-text{
  white-space: nowrap;
    text-overflow: ellipsis;

  -webkit-mask-image: linear-gradient(to left, #0000 0px, #000f 1em, #000f 100%);
  mask-image: linear-gradient(to left, #0000 0px, #000f 1em, #000f 100%);

}

/* deprecated */
.timeline-clock{
  position: fixed;
  z-index:1100;
  background-color: #fffffe;
  width: 200px;
  height: 15px;
  font-size: 9px;
  font-weight: bold;
  color: white;
}
.timeline-clock-current{
  display: inline-block;
  width: 30px;
}
.timeline-clock-total{
  display: inline-block;
}
.timeline-clock-center{
    display: inline-block;
    width: 10px;
    text-align: center;
}

/* used */
.timeline-top-clock{
  position: absolute;
  right: 20px;
  top: 10px;
  color: #bbb;  
  font-size: 18px;
  font-weight: bold;
}
.timeline-top-clock-current{
  display: inline-block;
  width: 50px;
}
.timeline-top-clock-total{
  display: inline-block;
}
.timeline-top-clock-center{
    display: inline-block;
    width: 20px;
    text-align: center;
}



.times-container{
  position: sticky;
  top: 0px;
  z-index:299;
  padding-left: 200px;
  height: 15px;
  width: calc(100% - 200px);
  background-color: #fffffe;
  border-top:0px solid black;
  overflow:hidden;
  /*background: repeating-linear-gradient(
    to right,
    #505050,
    #505050 25px,
    #535353 25px,
    #535353 50px
  );*/

}
.times-container-click{
  background-color: red;
  width: 100%;
  opacity:0%;
  height:15px;
  position: absolute;
  top:0px;
  z-index:300;
}
.is_mod .times-container-click{
  cursor: pointer;
}

.time-marker{
  display: inline-block;
  width: 100px;
  height: calc(1em + 2px);
  position: relative;
  top: calc(16px - 1em - 2px);
  overflow: hidden;
  /*color: #fff;*/
  color: #989898;
}

.time-marker span{
  /*display: inline-block;*/
  padding-left: 5px;
  padding-top: 2px;
  font-size: 9px;
  height: calc(1em + 2px);
  font-weight: bold;
  background: linear-gradient(
    to right,
    #aaa5,
    #aaa5 2px,
    #0000 2px
  );
}

.infobox {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100px;
  /*background-color: #989898;*/
  background-color: #fffffe;
  /*background-image: url('logo.gif');*/
  background-repeat: no-repeat;
  background-position:10px 10px;
  background-position: bottom right;
  overflow: hidden;
  z-index:0;

}

.infobox-bg{

    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(152 152 152 / 100%);
    z-index: -1;

}
.infobox-title, .infobox-text{
  /*color: white;*/
  color: #6b6a6a;
  padding-left: 10px;
  width: 100%;
}
.infobox-title{
  font-weight: bold;
  width: 100%;
    z-index:1000;
      font-size: 18px;
      padding-top:10px;

}
.infobox-text{
    font-size: 14px;
    padding-top:10px;
    white-space: pre-wrap;
}

.timeline-top{
  position: absolute;
  top: 0px;
  height: 55px;
  width: 100%;
  z-index:1;
  background-color: #fffffe;

}

.timeline-top-title{
  font-size: 18px;
  /*color: #fff;*/
  color: #000;
  padding-left:10px;
  padding-top: 10px;
  font-weight: bold;
  width: calc(100% - 180px);
  white-space: nowrap;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to left, #0000 0px, #000f 100px, #000f 100%);
  mask-image: linear-gradient(to left, #0000 0px, #000f 100px, #000f 100%);
}


.timeline-top-subheader{
  font-size: 12px;
  color: #888;
  font-style: italic;
  padding-left:10px;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 85px)
}

.button-users{
  position: absolute;
  bottom: 0px;
  right: 6px;
  cursor:pointer;
  width: 39px;
    height: 35px;

  background-image: url("button-users3.gif");
    background-repeat: no-repeat;
    background-size: 39px;
   /* opacity: 0.6;*/
   filter: brightness(0.8);
}
.button-users:hover{
  opacity: 0.9;
  cursor: pointer;
}
.button-users:active{
  opacity: 1.0;
    cursor: pointer;

}




.timeline-zoom {
  height: 50px;
  background-color: #eee;
  border-bottom:2px solid #eee;
  color: #0000;
  position: fixed;
    z-index: 1000;
    width: 200px;

}

.zoom-left {
  left: 50%;
  top: 0%;
  width: 25%;
  height: 100%;
  cursor: zoom-out;
}
.zoom-left div {
  transform: rotate(0deg);
}
.zoom-right {
  left: 75%;
  top: 0%;
  width: 25%;
  height: 100%;
  cursor: zoom-in;
}
.zoom-right div{
  transform: rotate(180deg);
}

.zoom-up {
  left: 0%;
  top: 0%;
  width: 50%;
  height: 50%;
  z-index: 1000;

  cursor: zoom-out;
}
.zoom-up div {
  transform: rotate(90deg);
}
.zoom-down {
  left: 0%;
  top: 50%;
  width: 50%;
  height: 50%;
  cursor: zoom-in;
}
.zoom-down div{
  transform: rotate(270deg);
}

.timeline-zoom div{
    position: absolute;
}
.timeline-zoom div div {
  background: url("button-zoom.gif") no-repeat center;
  width: 100%;
  height: 100%;
}

.timeline-zoom div:hover {
  background-color: #ddd;
}

.block-grid-clicker{
  width: 100%;
  height: 100%;
  opacity:0.0;
  background-color:yellow;
  z-index:-10;
}
.is_mod .block-grid-clicker{
    cursor: crosshair;

}

.timeline-middle{
  position: absolute;
  top: 50px;
  height: calc(100% - 100px - 50px);
  width: 100%;
  overflow: scroll;
  background-color: #eee;
}


.is_editing{
  filter: blur(1px)
}


.add-participant-button{
  padding: 0px 0px 4px 9px;
    background-color: #eee;
    font-size: 20px;
}

.add-participant-button:hover{
  background-color: white;
  cursor: pointer;
}