

.currently-editing-container {
	height: calc(100%);
	user-select: none;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index:200000;
	/*max-height: calc(500px - 0px);*/
	width: calc(400px);
	text-align: left;
	overflow: hidden;
	border-radius: 10px 10px 10px 10px;
	color: black;
  	background-color: #f0f0f0;
  	filter:drop-shadow(12px 10px 10px black)

}

.currently-editing-x{
	position: absolute;
	font-size:24px;
	font-weight: bold;
	left: 0px;
	top: 0px;
	opacity:0.3;
	width: 50px;
	height: 50px;
	text-align: center;
	display: grid;
	align-items: center;
	justify-content: center;
}
.currently-editing-x:hover{
	opacity:1.0;
	cursor:pointer;
}


.currently-editing-container.hide {
	width: 0px;
    -webkit-transition: width 0.1s linear;
       -moz-transition: width 0.1s linear;
        -ms-transition: width 0.1s linear;
         -o-transition: width 0.1s linear;
            transition: width 0.1s linear;
}

.currently-editing-container.show {
     -webkit-transition: width 0.1s linear;
        -moz-transition: width 0.1s linear;
         -ms-transition: width 0.1s linear;
          -o-transition: width 0.1s linear;
             transition: width 0.1s linear;
}


.currently-editing-header{
	/*font-weight: bold;*/
	padding: 20px;
	padding-top:50px;
	font-size: 24px;
	color: #000;
}


.currently-editing-container form{
	margin: 20px 20px 20px 20px;
}
.currently-editing-container label{
	display: block;
}
.currently-editing-container input[type=text], .currently-editing-container .form-uneditable, .currently-editing-container textarea{
	display: block;
	margin: 5px 0px 20px 0px;
}
.currently-editing-container .form-uneditable {
	color: #888;
}
.currently-editing-container input[type=text], .currently-editing-container textarea{
	border-radius: 5px;
	background-color: #444;
	color: white;
	width: calc(100% - 40px);
	padding: 20px;
	font-size: 16px;
	font-family: sans-serif;
}
 .currently-editing-container textarea{
 	height: 50px;
 }
.delete-button, .submit-edit-button{
	display: block;

    padding: 20px;
    border: 0px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    opacity: 1.0;
    width: 100%;
    margin: 20px 0px;
}
.delete-button:hover, .submit-edit-button:hover{
	opacity: 0.7;
	cursor: pointer;
}

.submit-edit-button{
	z-index: 100;
}
.delete-button{
		z-index:99;
	background-color: #ff7a7a;
	position: absolute;
    bottom: 0px;
    width: calc(100% - 40px);
}

.submit-edit-button{
		background-color: #6b6b6b;
}

.hue-picker{
	    margin-bottom: 20px;
	    filter: saturate(0.6);
}