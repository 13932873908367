

.users-online-container {
	height: 100%;
	user-select: none;
	position: absolute;
	top: 0px;
	right: 0px;
	z-index:100000;
	/*max-height: calc(500px - 0px);*/
	width: calc(200px - 0px);
	text-align: left;
	overflow: hidden;
	border-radius: 10px 0px 0px 10px;
	color: black;
  	background-color: #f0f0f0;
  	filter:drop-shadow(-10px 0px 12px black)

}

.users-online-header{
	/*font-weight: bold;*/
	padding: 20px;
	font-size: 18px;
	color: #000;
}

.users-online-x{
	position: absolute;
	font-size:24px;
	font-weight: bold;
	right: 0px;
	bottom: 0px;
	opacity:0.3;
	width: 50px;
	height: 50px;
	text-align: center;
	display: grid;
	align-items: center;
	justify-content: center;
}
.users-online-x:hover{
	opacity:1.0;
	cursor:pointer;
}

.users-online-list{
	padding: 0px 20px;
	height: calc(100% - 115px);
    overflow-x: hidden;
    overflow-y: scroll;
}
.user-online{
	color: #000;
	font-size:20px;
	padding-top: 10px;
	white-space: nowrap;
	overflow: hidden;
}


.users-online-container.hide {
	width: 0px;
    -webkit-transition: width 0.1s linear;
       -moz-transition: width 0.1s linear;
        -ms-transition: width 0.1s linear;
         -o-transition: width 0.1s linear;
            transition: width 0.1s linear;
}

.users-online-container.show {
     -webkit-transition: width 0.1s linear;
        -moz-transition: width 0.1s linear;
         -ms-transition: width 0.1s linear;
          -o-transition: width 0.1s linear;
             transition: width 0.1s linear;
}


.my-user-highlight{
	font-weight: bold;
}