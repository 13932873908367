

.hello-container {
	height: calc(100%);
	user-select: none;
	margin: auto ;
	height: 100%;
	/*top: 0px;
	left: 0px;*/
	z-index:200000;
	/*max-height: calc(500px - 0px);*/
	width: calc(400px);
	text-align: left;
	overflow: hidden;
	border-radius: 10px 10px 10px 10px;
	color: black;
  	/*background-color: #f0f0f0;*/
  	/*filter:drop-shadow(12px 10px 10px black)*/

}

.hello-header{
	/*font-weight: bold;*/
	padding: 20px;
	padding-top:50px;
	font-size: 24px;
	color: #000;
}


.hello-container form{
	margin: 20px 20px 20px 20px;
}
.hello-container label{
	display: block;
}
.hello-container input[type=text], .hello-container .form-uneditable, .hello-container textarea{
	display: block;
	margin: 5px 0px 20px 0px;
}
.hello-container .form-uneditable {
	color: #888;
}
.hello-container input[type=text], .hello-container textarea{
	border-radius: 5px;
	background-color: #444;
	color: white;
	width: calc(100% - 40px);
	padding: 20px;
	font-size: 16px;
	font-family: sans-serif;
}
 .hello-container textarea{
 	height: 50px;
 }



.link-button{
	display: block;

    padding: 20px;
    border: 0px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    opacity: 1.0;
    margin: 20px;
    text-align: center;
    background-color: #555;
}
.link-button:hover{
	opacity: 0.7;
	cursor: pointer;
}

	.list-buttons{
	display: flex;
  justify-content: space-between;

}
.list-buttons .link-button{
	margin: 0px;
	padding: 10px;
	width: 27%;
}

.list-room{
	margin: 20px;
	/*border: 1px solid black;*/
	border-radius: 5px;
	/*background-color: #ddd;*/
}
.list-title{
	font-size: 16px;
	padding: 10px;
}

.list-subheader{
	font-size: 12px;
	padding: 0px 10px 10px;
}

.link-button-red{
	background-color: #900;
}
.link-button-red{
	background-color: #900;
}


.this-and-that-logo{
	/*background-color: black;*/
	height: 400px;
	background-image: url("ttlogo-big.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center 4px;
    overflow: hidden;
}